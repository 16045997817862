<template>
  <v-container fluid grid-list-md align-center pa-0>
    <ComponentAdminReport72 />
  </v-container>
</template>

<script>
import ComponentAdminReport72 from "@/components/admin/ComponentAdminReport72";
export default {
  components: {
    ComponentAdminReport72,
  },
};
</script>

<style scoped></style>
