<template>
  <v-container grid-list-lg pt-4>
    <div style="margin-top:17rem !important" v-if="isLoading">
      <v-layout row wrap flex justify-center align-center fill-height>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
    </div>
    <h1>ค้นหาข้อมูล</h1>
    <!-- <div class="" v-if="!isLoading && totalPlantByProvince.length !== 0">
      <v-layout row wrap mt-1>
        <v-flex md12 xs12 sm12>
          <v-card flat color="secondary">
            <v-card-text>
              <span class="headline white--text">ประเทศไทย (ต้น)</span>
              <v-layout
                row
                wrap
                justify-space-between
                align-end
                style="margin-top:-10px"
              >
                <v-flex sm12 xs12 md12 class="pb-0">
                  <span
                    class="display-3 lineheight white--text font-weight-bold"
                    >{{ totalPlant }}</span
                  >
                </v-flex>
                <v-flex sm12 xs12 md12 style="text-align:right" class="pb-0">
                   <span class="headline white--text font-weight-bold" style="color:#605559 !important">จำนวนผู้ลงทะเบียนปลูก 130,135 ท่าน</span><br> -->
    <!-- <span
                    class="headline white--text font-weight-bold"
                    style="color:#605559 !important"
                    >จำนวนผู้ลงทะเบียนปลูก
                    {{ numberOfPeopleByTotlePlant }} ท่าน</span
                  ><br /> -->
    <!-- <span class="title white--text font-weight-bold" style="color:#546465 !important">จำนวนผู้ลงทะเบียน {{userRegister}} ท่าน</span> -->
    <!-- </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap mt-1 justify-end>
        <v-flex md2 xs12 sm3 offset-md-2>
          <v-btn depressed @click="sort()">
            <v-icon>sort</v-icon>
            เรียงลำดับ
          </v-btn>
        </v-flex>
        <v-flex md6 xs12 sm6>
          <v-card flat color="secondary">
            <v-text-field
              v-model="search"
              label="Search..."
              solo
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              append-icon="search"
            ></v-text-field>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex md2 xs12 sm6 v-for="(i, index) in filteredList" :key="index">
          <v-card flat color="secondary">
            <v-card-text>
              <span class="title white--text">{{ i.name }} (ต้น)</span><br />
              <v-layout
                row
                wrap
                justify-space-between
                align-end
                style="margin-top:-10px"
              >
                <v-flex sm12 xs12 md12 class="pb-0">
                  <span
                    class="display-2 white--text font-weight-bold lineheight30"
                    >{{ commaSeparateNumber(i.userPlantSum) }}</span
                  >
                </v-flex>
                <v-flex sm12 xs12 md12 style="text-align:right" class="pb-0">
                  <span
                    class="title white--text font-weight-bold"
                    style="color:#605559 !important;"
                    >ผู้ลงทะเบียนปลูก
                    {{ commaSeparateNumber(i.numberofpeople) }} ท่าน</span
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="filteredList.length === 0" justify-center mt-2>
        <v-flex xs1>
          <h2>ไม่พบข้อมูล</h2>
        </v-flex>
      </v-layout>
    </div> -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      totalPlant: "",
      totalPlantByProvince: [],
      isSort: true,
      isLoading: true,
      numberOfPeopleByTotlePlant: "",
      userRegister: "",
    };
  },
  created() {
    this.isLoading = true;
    this.queryStatTotal();
    this.queryStatByProvince();
    this.$emit("activeMenu", "statistic");
  },
  computed: {
    filteredList() {
      return this.totalPlantByProvince.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    queryStatTotal() {
      this.axios
        .post(process.env.VUE_APP_IPFLASK + "/queryStat/totalUserPlant")
        .then((response) => {
          // console.log(response.data);
          this.totalPlant = this.commaSeparateNumber(
            response.data.userPlantSum
          );
          this.numberOfPeopleByTotlePlant = this.commaSeparateNumber(
            response.data.numberofpeople
          );
          this.userRegister = this.commaSeparateNumber(
            response.data.userRegister
          );
        });
    },
    queryStatByProvince() {
      this.axios
        .post(
          process.env.VUE_APP_IPFLASK + "/queryStat/totalUserPlant/byProvince"
        )
        .then((response) => {
          // console.log(response.data);
          this.totalPlantByProvince = response.data;
          this.isLoading = false;
        });
    },
    commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      return val;
    },
    sort() {
      if (this.isSort) {
        this.isSort = false;
        return this.totalPlantByProvince.sort(function(a, b) {
          return b.userPlantSum - a.userPlantSum;
        });
      } else {
        this.isSort = true;
        return this.totalPlantByProvince.sort(function(a, b) {
          return a.userPlantSum - b.userPlantSum;
        });
      }
    },
  },
};
</script>

<style scoped>
.lineheight {
  line-height: 1.2 !important;
}
.lineheight30 {
  line-height: 1.2 !important;
}
</style>
