import { render, staticRenderFns } from "./ComponentAdminReport72.vue?vue&type=template&id=b7c585f4&scoped=true&"
import script from "./ComponentAdminReport72.vue?vue&type=script&lang=js&"
export * from "./ComponentAdminReport72.vue?vue&type=script&lang=js&"
import style0 from "./ComponentAdminReport72.vue?vue&type=style&index=0&id=b7c585f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7c585f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/builds/rfd/seeding_72/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {VContainer,VLayout,VProgressCircular})
